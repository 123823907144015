import React from 'react'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import {
  Avatar,
  Box,
  Cell,
  Icon,
  Input,
  Item,
  Subheader,
  Token,
  VStack,
  List,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplatesInterface,
} from '@src/interfaces/documentsTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import NewDatePicker from '@components/Inputs/NewDatePicker/NewDatePicker'
import { selectorKeys } from '@src/constants/api'

import {
  TemplateField,
  getFieldsByPage,
  getIndexedFieldKey,
  getSourceIdToLabel,
  getSourceOptions,
  hasFieldsOnPage,
  mapTypeToAvatar,
  recipientOptions,
  getDataFromIndexedKey,
  hasAnyFields,
  ErrorsByFieldsKeys,
  FieldType,
  fieldTypes,
  IndexedFieldKey,
  SelectedFieldInterface,
} from './common'

type FieldSettingsProps = React.PropsWithChildren<TemplateField>

const FieldSettings = ({
  id,
  type,
  data,
  isSelected,
  setSelected,
  setUnselected,
  children,
  errors,
  clearError,
}: FieldSettingsProps) => {
  const { idx } = getDataFromIndexedKey(id)

  const hasErrors = !isEmpty(errors)
  const signerRoleError = errors?.signer_role?.[0]
  const sqlSourceError = errors?.sql_source?.[0]
  const nonFieldErrors = Object.values(omit(errors, 'signer_role', 'sql_source')).flat()

  const signerSelector = (
    <RadioSelectInput
      label="Signer"
      options={recipientOptions}
      value={data.signer_role}
      onChange={newValue => {
        if (newValue) {
          clearError?.(id, 'signer_role')
          data.signer_role = newValue
        }
      }}
      searchable={false}
      hasError={!!signerRoleError}
      message={signerRoleError}
    />
  )

  return (
    <VStack space="s-4">
      <Item
        use="button"
        type="button"
        onClick={isSelected ? setUnselected : setSelected}
        aria-pressed={isSelected}
      >
        <Item.Avatar>
          {hasErrors ? (
            <Avatar useIcon="ExclamationTriangle" color={Token.color.error} />
          ) : (
            mapTypeToAvatar[type]
          )}
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {capitalize(type)} field {idx + 1} settings
          </Item.Title>
        </Item.Content>
        <Item.Side>
          <Icon name={isSelected ? 'ChevronUp' : 'ChevronDown'} />
        </Item.Side>
      </Item>
      {isSelected && (
        <>
          {!!nonFieldErrors?.length && (
            <Box mx="s-8" p="s-8" pb="s-16">
              <List variant="compact">
                {nonFieldErrors.map(errMessage => (
                  <List.Item
                    key={errMessage}
                    useIcon="ExclamationMarkOutline"
                    color={Token.color.red}
                  >
                    {errMessage}
                  </List.Item>
                ))}
              </List>
            </Box>
          )}
          <VStack p="s-4" pt={0} space="s-4">
            {type !== 'signature' && (
              <RadioSelectInput
                label="Data source"
                options={getSourceOptions(type)}
                value={data.source_type}
                onChange={newValue => {
                  if (newValue) {
                    data.source_type = newValue
                    data.custom_value = null
                  }
                }}
                searchable={false}
              />
            )}
            {data.source_type.id === 'to_be_filled' && (
              <>
                <Input
                  label="Field label"
                  value={data.placeholder}
                  onChange={e => {
                    data.placeholder = e.currentTarget.value
                  }}
                />
                {signerSelector}
              </>
            )}
            {data.source_type.id === 'sql_source' && (
              <>
                <RadioSelectInput
                  label="SQL source"
                  selector={selectorKeys.document_esignature_sql_sources}
                  value={data.sql_source}
                  onChange={newValue => {
                    if (newValue) {
                      clearError?.(id, 'sql_source')
                      data.sql_source = newValue
                    }
                  }}
                  hasError={!!sqlSourceError}
                  message={sqlSourceError}
                />
                {signerSelector}
              </>
            )}
            {children}
          </VStack>
        </>
      )}
    </VStack>
  )
}

type TypedFieldSettingsProps = Omit<FieldSettingsProps, 'type'>

const TextFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const restErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings {...props} type="text" errors={restErrors}>
      {data.source_type.id === 'custom_value' && (
        <Input
          label={getSourceIdToLabel('text').custom_value}
          value={data.custom_value || undefined}
          onChange={e => {
            clearError?.(id, 'custom_value')
            data.custom_value = e.currentTarget.value
          }}
          aria-invalid={!!customValueError}
          errorMessage={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const NumberFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const restErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings {...props} type="number" errors={restErrors}>
      {data.source_type.id === 'custom_value' && (
        <Input
          type="number"
          label={getSourceIdToLabel('number').custom_value}
          value={data.custom_value || undefined}
          onChange={e => {
            clearError?.(id, 'custom_value')
            data.custom_value = e.currentTarget.value
          }}
          aria-invalid={!!customValueError}
          errorMessage={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const DateFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const restErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings {...props} type="date" errors={restErrors}>
      {data.source_type.id === 'custom_value' && (
        <NewDatePicker
          label={getSourceIdToLabel('date').custom_value}
          value={data.custom_value as string}
          onChange={newDate => {
            if (newDate) {
              clearError?.(id, 'custom_value')
              data.custom_value = newDate.toISOString()
            }
          }}
          hasError={!!customValueError}
          error={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const MoneyFieldSettings = (props: TypedFieldSettingsProps) => {
  const data = props.data as DocumentsTemplateMoneyFieldInterface

  const { id, errors, clearError } = props
  const customValueError = errors?.custom_value?.[0]
  const currencyError = errors?.currency?.[0]
  const restErrors = omit(errors, ['custom_value', 'currency'])

  return (
    <FieldSettings {...props} type="money" errors={restErrors}>
      {data.source_type.id === 'custom_value' && (
        <>
          <Input
            type="money"
            label={getSourceIdToLabel('money').custom_value}
            value={data.custom_value}
            onChange={newValue => {
              if (newValue != null) {
                clearError?.(id, 'custom_value')
                data.custom_value = newValue
              }
            }}
            aria-invalid={!!customValueError}
            errorMessage={customValueError}
          />
          <RadioSelectInput
            label="Currency"
            value={data.currency}
            selector={selectorKeys.currencies}
            onChange={newValue => {
              if (newValue) {
                clearError?.(id, 'currency')
                data.currency = newValue
              }
            }}
            hasError={!!currencyError}
            message={currencyError}
          />
        </>
      )}
    </FieldSettings>
  )
}

const SignatureFieldSettings = (props: TypedFieldSettingsProps) => {
  return <FieldSettings type="signature" {...props} />
}

type Props = {
  totalPages?: number
  selectedFieldKey: IndexedFieldKey | undefined
  setSelectedFieldKey: (id: IndexedFieldKey | undefined) => void
  errorsByFieldsKeys: ErrorsByFieldsKeys
  clearError: (indexedKey: IndexedFieldKey, errorField: string) => void
}
export const FieldsSettingsBar = ({
  totalPages,
  selectedFieldKey,
  setSelectedFieldKey,
  errorsByFieldsKeys,
  clearError,
}: Props) => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const getIsActiveProps = (key: string): SelectedFieldInterface => ({
    isSelected: key === selectedFieldKey,
    setSelected: () => setSelectedFieldKey(key),
    setUnselected: () => setSelectedFieldKey(undefined),
  })

  if (!hasAnyFields(values)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ExclamationTriangle" color={Token.color.blue} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>No any fields have been added yet</Item.Title>
          <Item.Description>Click "Add data field" to create a new one</Item.Description>
        </Item.Content>
      </Item>
    )
  }

  return (
    <VStack maxHeight="calc(100vh - 100px)" overflow="scroll" pr="s-4">
      {[...Array(totalPages).keys()].map(pageIdx => {
        const pageNum = pageIdx + 1

        if (!hasFieldsOnPage(pageNum, values)) {
          return null
        }
        return (
          <Box key={pageIdx}>
            <Subheader variant="nested">
              <Subheader.Title>Page {pageNum}</Subheader.Title>
            </Subheader>
            <Cell p="s-4">
              <VStack space="s-8" width="100%" overflow="scroll">
                {fieldTypes.map(fieldType => {
                  const settingsComponentByType: Record<FieldType, React.ElementType> = {
                    text: TextFieldSettings,
                    number: NumberFieldSettings,
                    date: DateFieldSettings,
                    money: MoneyFieldSettings,
                    signature: SignatureFieldSettings,
                  }

                  return getFieldsByPage(fieldType, pageNum, values).map((field, idx) => {
                    const key = getIndexedFieldKey(fieldType, pageIdx, idx)
                    const SettingsComponent = settingsComponentByType[fieldType]
                    return (
                      <SettingsComponent
                        id={key}
                        key={key}
                        data={field}
                        errors={errorsByFieldsKeys[key]}
                        clearError={clearError}
                        {...getIsActiveProps(key)}
                      />
                    )
                  })
                })}
              </VStack>
            </Cell>
          </Box>
        )
      })}
    </VStack>
  )
}
