import React, { useEffect, useRef, useState } from 'react'
import upperFirst from 'lodash/upperFirst'
import omit from 'lodash/omit'
import {
  Subheader,
  Text,
  Token,
  Box,
  ActionButton,
  Dropdown,
  Item,
  HStack,
  Flex,
  Sticky,
  Spacer,
  VStack,
  StatusWidget,
  MoreBar,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { UploadTemplateFile } from '@src/apps/People/Documents/Template/Edit/common/UploadTemplateFile'
import { PdfPreview } from '@src/features/PdfPreview'
import { downloadTemplateFileAsBlob } from '@src/api/documentsTemplates'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { DragAndDropOverlay } from '@src/apps/People/Documents/Template/Edit/ESignature/Fields/DragAndDropOverlay'
import { PdfPreviewPagination } from '@src/features/PdfPreview/common'

import { FieldsSettingsBar } from './Fields/SettingsBar'
import {
  addNewField,
  fieldTypes,
  FieldType,
  mapTypeToAvatar,
  getIndexedFieldKey,
  getDataFromIndexedKey,
  getFieldsByPage,
  hasAnyFields,
  mapFieldsValidationErrorsToIndexedKeys,
  IndexedFieldKey,
} from './Fields/common'

type DropdownItemProps = {
  fieldType: FieldType
  onClick: (f: FieldType) => void
}
const DropdownItem = ({ fieldType, onClick }: DropdownItemProps) => (
  <Dropdown.Item p={0}>
    <Item
      use="label"
      aria-label={`Attach ${fieldType} field`}
      onClick={() => onClick(fieldType)}
    >
      <Item.Avatar>{mapTypeToAvatar[fieldType]}</Item.Avatar>
      <Item.Content>
        <Item.Title mr="128px">{upperFirst(fieldType)} field</Item.Title>
      </Item.Content>
    </Item>
  </Dropdown.Item>
)

const PdfLoadingErrorBanner = () => (
  <StatusWidget>
    <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images/3D083.png" />
    <StatusWidget.Title>Failed to load pdf template</StatusWidget.Title>
    <StatusWidget.Description>
      Please reload the page or try again later
    </StatusWidget.Description>
  </StatusWidget>
)

const PdfRenderingErrorBanner = ({ onRetry }: { onRetry: () => void }) => (
  <StatusWidget>
    <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images/3D083.png" />
    <StatusWidget.Title>Failed to preview pdf template</StatusWidget.Title>
    <StatusWidget.Description>Try uploading another one</StatusWidget.Description>
    <StatusWidget.Action useIcon="Retry" onClick={onRetry}>
      Upload another file
    </StatusWidget.Action>
  </StatusWidget>
)

export const TabUploadFile = () => {
  const { values, submit } = useLapeContext<DocumentsTemplatesInterface>()

  const [fileUrl, setFileUrl] = useState<string>()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [pageNum, setPageNum] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [showFieldsSettings, setShowFieldsSettings] = useState(hasAnyFields(values))
  const [loadingError, setLoadingError] = useState<Error>()
  const [showFileUploader, setShowFileUploader] = useState(!values.file_name)
  const [selectedFieldKey, setSelectedFieldKey] = useState<IndexedFieldKey>()
  const [errorsByFieldsKeys, setErrorsByFieldsKeys] = useState<
    Record<string, Record<string, string[]>>
  >({})

  const dropdownAnchorRef = useRef<HTMLButtonElement>(null)
  const paginationRef = useRef<PdfPreviewPagination | null>(null)

  useEffect(() => {
    if (!values.file_name) {
      return
    }
    setFileUrl(undefined)

    downloadTemplateFileAsBlob(values.id)
      .then(res => setFileUrl(res))
      .catch(e => setLoadingError(e))
  }, [values.file_name])

  useEffect(() => {
    if (selectedFieldKey) {
      const { pageNum: currentPageNum } = getDataFromIndexedKey(selectedFieldKey)
      paginationRef.current?.setCurrent(currentPageNum)
    }
  }, [selectedFieldKey])

  const docColMinWidth = 600
  const docColMaxWidth = 720
  const fieldsColMinWidth = 350
  const fieldsColMaxWidth = 400
  const stickyOffset = 68

  if (loadingError) {
    return (
      <PageBody>
        <PdfLoadingErrorBanner />
      </PageBody>
    )
  }
  return (
    <>
      <Flex flexDirection="column" gap="s-32">
        <Box>
          <Subheader>
            <Subheader.Title>
              {values.file_name
                ? 'Configure the template'
                : 'Upload the template for your eSignature'}
            </Subheader.Title>
          </Subheader>
          <Text variant="caption" color={Token.color.greyTone50}>
            {values.file_name
              ? 'Add all fields and placeholders to your document'
              : 'This is the base file to be used to generate the signatures on'}
          </Text>
        </Box>
        <VStack space="s-16">
          <MoreBar>
            <MoreBar.Action
              useIcon={showFieldsSettings ? 'EyeHide' : 'EyeShow'}
              onClick={() => setShowFieldsSettings(!showFieldsSettings)}
            >
              {showFieldsSettings ? 'Hide' : 'Show'} fields settings
            </MoreBar.Action>
            {values.file_name && (
              <MoreBar.Action useIcon="Retry" onClick={() => setShowFileUploader(true)}>
                Upload another file
              </MoreBar.Action>
            )}
          </MoreBar>
          <HStack gap="s-16" maxWidth={docColMaxWidth + fieldsColMaxWidth + 16}>
            <Box
              flex="1 1 70%"
              minWidth={docColMinWidth}
              maxWidth={showFieldsSettings ? docColMaxWidth : undefined}
            >
              {showFileUploader ? (
                <PageBody>
                  <UploadTemplateFile
                    onAfterUploaded={() => setShowFileUploader(false)}
                  />
                </PageBody>
              ) : (
                <PdfPreview
                  fileUrl={fileUrl}
                  toolbarStickyOffset={stickyOffset}
                  toolbarActions={
                    <Box m="s-16">
                      <ActionButton
                        variant="primary-on-blue"
                        useIcon="Plus"
                        ref={dropdownAnchorRef}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        Add data field
                      </ActionButton>
                      {pageNum && (
                        <Dropdown
                          open={isDropdownOpen}
                          anchorRef={dropdownAnchorRef}
                          onClose={() => setIsDropdownOpen(false)}
                        >
                          <VStack backgroundColor={Token.color.widgetBackground}>
                            {fieldTypes.map(fieldType => {
                              const fieldsOfSameTypeOnPage = getFieldsByPage(
                                fieldType,
                                pageNum,
                                values,
                              )
                              return (
                                <DropdownItem
                                  key={fieldType}
                                  fieldType={fieldType}
                                  onClick={() => {
                                    setSelectedFieldKey(
                                      getIndexedFieldKey(
                                        fieldType,
                                        pageNum - 1,
                                        fieldsOfSameTypeOnPage.length,
                                      ),
                                    )
                                    addNewField(values, fieldType, pageNum)
                                    setShowFieldsSettings(true)
                                    setIsDropdownOpen(false)
                                  }}
                                />
                              )
                            })}
                          </VStack>
                        </Dropdown>
                      )}
                    </Box>
                  }
                  onPageNumChange={(pNum, pTotal) => {
                    setPageNum(pNum)
                    setTotalPages(pTotal)
                  }}
                  renderErrorBanner={() => (
                    <PdfRenderingErrorBanner onRetry={() => setShowFileUploader(true)} />
                  )}
                  renderPageOverlay={(
                    currentScale,
                    currentPageNum,
                    viewportWidth,
                    viewportHeight,
                  ) => {
                    return (
                      <DragAndDropOverlay
                        pageNum={currentPageNum}
                        scale={currentScale}
                        width={viewportWidth}
                        height={viewportHeight}
                        selectedFieldKey={selectedFieldKey}
                        setSelectedFieldKey={setSelectedFieldKey}
                        errorsByFieldsKeys={errorsByFieldsKeys}
                      />
                    )
                  }}
                  setPaginationRef={p => {
                    paginationRef.current = p
                  }}
                />
              )}
              <Spacer height="30vh" />
            </Box>
            {showFieldsSettings && (
              <Box flex="1 1 30%">
                <Sticky
                  top={stickyOffset}
                  minWidth={fieldsColMinWidth}
                  maxWidth={fieldsColMaxWidth}
                >
                  <FieldsSettingsBar
                    totalPages={totalPages}
                    selectedFieldKey={selectedFieldKey}
                    setSelectedFieldKey={setSelectedFieldKey}
                    errorsByFieldsKeys={errorsByFieldsKeys}
                    clearError={(indexedKey: IndexedFieldKey, errorField: string) => {
                      setErrorsByFieldsKeys({
                        ...errorsByFieldsKeys,
                        [indexedKey]: omit(errorsByFieldsKeys[indexedKey], [
                          errorField,
                          'non_field_errors',
                        ]),
                      })
                    }}
                  />
                </Sticky>
              </Box>
            )}
          </HStack>
        </VStack>
      </Flex>
      <PageActions>
        <NewSaveButtonWithPopup
          onClick={() => {
            setErrorsByFieldsKeys({})
            return submit()
          }}
          errorHandler={err => {
            setErrorsByFieldsKeys(
              mapFieldsValidationErrorsToIndexedKeys(err.response?.data, values),
            )
          }}
        />
      </PageActions>
    </>
  )
}
