import React, { useMemo } from 'react'
import {
  ActionButton,
  Avatar,
  CounterWidget,
  CounterWidgetSkeleton,
  Group,
  Item,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetJobPostingsApplicationFormSettings } from '@src/api/settings'
import { useGetPredefinedSectionsConfig } from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { InterviewRoundState } from '@src/interfaces/interviewTool'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'

const useCompletedCount = () => {
  const { stats, ...ctx } = useCandidatePipelineData([
    {
      columnName: 'active_interview_round__state',
      filters: [{ id: InterviewRoundState.active, name: InterviewRoundState.active }],
    },
    {
      columnName: 'origin',
      filters: [{ id: 'application', name: 'application' }],
    },
  ])
  const total = stats?.find(({ stage_type }) => stage_type === 'Total')
  return {
    count: total ? total.count : 0,
    ...ctx,
  }
}

const ApplicationFormOverview = () => {
  const params = useParams()
  const { values } = useLapeContext<JobPostingInterface>()
  const { count: completedCount, loading } = useCompletedCount()
  const { data: applicationSettings } = useGetJobPostingsApplicationFormSettings()
  const careersPredefinedSections = useGetPredefinedSectionsConfig()
  const careersPredefinedSectionsCount = useMemo(
    () => Array.from(careersPredefinedSections).filter(([_, v]) => v.visible).length ?? 0,
    [careersPredefinedSections],
  )
  const sectionCount =
    (applicationSettings?.global_sections?.length ?? 0) +
    (values.application_form_sections?.length ?? 0) +
    careersPredefinedSectionsCount
  const questionsCount = useMemo(() => {
    return values.application_form_sections?.reduce(
      (acc, { questions }) => acc + questions.length,
      0,
    )
  }, [values.application_form_sections])
  if (loading) {
    return (
      <Group>
        <ItemSkeleton />
        <CounterWidget>
          <CounterWidgetSkeleton />
          <CounterWidgetSkeleton />
          <CounterWidgetSkeleton />
        </CounterWidget>
      </Group>
    )
  }
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Application form</Item.Title>
          <Item.Description>
            Application form questions candidates will answer when applying to job posting
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM, params)}
          >
            View
          </ActionButton>
        </Item.Side>
      </Item>
      <CounterWidget>
        <CounterWidget.Column>
          <CounterWidget.Description>{completedCount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Completed</CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{sectionCount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Sections</CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{questionsCount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">
            Total questions
          </CounterWidget.Subtitle>
        </CounterWidget.Column>
      </CounterWidget>
    </Group>
  )
}

export default ApplicationFormOverview
