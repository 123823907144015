import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Group,
  InputGroup,
  Item,
  Spinner,
  Switch,
  TextArea,
  Token,
  Widget,
} from '@revolut/ui-kit'
import {
  ArchiveButton,
  ArchivingProvider,
  BulkArchiveButton,
  RememberedDataInterface,
  saveRememberArchivingData,
  useArchiving,
  usePrefillRememberedChoice,
} from '@components/ArchivingCandidateSidebar/common'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { SendCandidateEmailInterface, WhenToSend } from '@src/interfaces/hiringProccess'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import {
  bulkCandidatesEmailsRequests,
  candidateEmailsRequests,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Side } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { clearCVArchivingChoice } from '@src/pages/Forms/CVScreening/utils'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import pluralize from 'pluralize'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  candidate?: CandidateInterface
  candidateIds?: number[]
  open: boolean
  onClose: () => void
  onAfterArchive?: () => void
  onLoading?: (isLoading: boolean) => void
  noSuccessPopup?: boolean
  showInvalidPlaceholdersWarning?: boolean
  isLoading?: boolean
}

const ArchivingCandidateSidebar = ({
  candidate,
  candidateIds,
  onClose,
  onLoading,
  onAfterArchive,
  noSuccessPopup,
  showInvalidPlaceholdersWarning,
  isLoading,
}: Omit<Props, 'open'>) => {
  const [rememberChoiceChecked, setRememberChoiceChecked] = useState(false)
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const {
    reason,
    setReason,
    comments,
    setComments,
    archiveAll,
    setArchiveAll,
    setSendEmail,
    sendEmail,
    setRoundId,
    roundId,
  } = useArchiving()

  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()

  const { loading } = usePrefillRememberedChoice(
    setRememberChoiceChecked,
    candidate,
    candidateIds,
  )

  const dataToRemember: RememberedDataInterface = useMemo(
    () => ({
      reason,
      comments,
      sendEmail,
      archiveAll,
      when_to_send: values.when_to_send,
      custom_sending_datetime: values.custom_sending_datetime,
      email_template: values.email_template,
      subject: values.subject,
      email_body: values.email_body,
      recipients_bcc: values.recipients_bcc,
      recipients_cc: values.recipients_cc,
      sender_type: values.sender_type,
      attachments:
        values.attachments?.map(item => ('id' in item ? item : null))?.filter(Boolean) ||
        [],
    }),
    [
      reason,
      comments,
      sendEmail,
      archiveAll,
      values.when_to_send,
      values.custom_sending_datetime,
      values.email_template,
      values.subject,
      values.email_body,
      values.recipients_bcc,
      values.recipients_cc,
      values.sender_type,
      values.attachments,
    ],
  )

  const saveRememberChoiceData = useCallback(() => {
    if (rememberChoiceChecked) {
      saveRememberArchivingData(dataToRemember)
    }
  }, [dataToRemember, rememberChoiceChecked])

  useEffect(() => {
    saveRememberChoiceData()
  }, [dataToRemember, rememberChoiceChecked])

  useEffect(() => {
    if (candidateIds) {
      values.candidate_ids = candidateIds
    }
  }, [candidateIds])

  useEffect(() => {
    if (!candidate) {
      return
    }

    const roundData = candidate.active_interview_round

    if (roundData) {
      setRoundId(candidate.active_interview_round?.id)
    }
  }, [candidate])

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, candidate?.id)
  }

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" data-testid="loader">
        <Spinner color="blue" size={96} />
      </Flex>
    )
  }

  return (
    <>
      <InputGroup>
        <RadioSelectInput<OptionInterface>
          value={
            reason?.id
              ? {
                  id: reason?.id,
                  name: reason?.name,
                }
              : undefined
          }
          onChange={setReason}
          selector={selectorKeys.interview_round_archived_reasons}
          label="Archival reason"
          disabled={isLoading}
        />

        <TextArea
          value={comments}
          onChange={e => {
            setComments(e.currentTarget.value || '')
          }}
          label="Comments (Optional)"
          autosize={false}
          rows={3}
          resize="vertical"
          disabled={isLoading}
        />

        <Group>
          <Item use="label">
            <Item.Content>
              <Item.Title color="grey-tone-50">Archive all opportunities</Item.Title>
            </Item.Content>
            <Item.Side>
              <Switch
                onChange={e => {
                  setArchiveAll(e.target.checked)
                }}
                checked={archiveAll}
                data-testid="archive_all_opportunities_switch"
              />
            </Item.Side>
          </Item>
          <Item use="label">
            <Item.Content>
              <Item.Title color="grey-tone-50">Send email</Item.Title>
            </Item.Content>
            <Item.Side>
              <Switch
                onChange={e => {
                  setSendEmail(e.target.checked)
                }}
                checked={sendEmail}
                data-testid="send_email_switch"
              />
            </Item.Side>
          </Item>
        </Group>
      </InputGroup>
      {sendEmail && (
        <Box mt="s-24">
          <SendEmailCommon
            fetchEmail={fetchEmail}
            insideSidebar
            showInvalidPlaceholdersWarning={showInvalidPlaceholdersWarning}
            actions={null}
          />
        </Box>
      )}
      <Widget p="s-16" mt="s-16">
        <NewCheckbox
          label="Remember my choice"
          checked={rememberChoiceChecked}
          onChange={e => {
            const checked = e.target.checked
            if (checked) {
              saveRememberChoiceData()
            } else {
              clearCVArchivingChoice()
            }
            setRememberChoiceChecked(checked)
          }}
        />
      </Widget>
      <Side.Actions horizontal style={{ padding: Token.space.s24 }}>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        {candidate && (
          <ArchiveButton
            candidateId={candidate.id}
            pending={loadingFetchEmail || loading}
            step={sendEmail ? 'email' : 'archive'}
            scheduled={values.when_to_send?.id !== WhenToSend.NOW}
            onAfterArchive={onAfterArchive}
            onLoading={onLoading}
            disabled={!roundId || !reason}
            noSuccessPopup={noSuccessPopup}
          />
        )}
        {candidateIds && (
          <BulkArchiveButton
            candidateIds={candidateIds}
            pending={loadingFetchEmail || loading}
            sendEmail={sendEmail}
            scheduled={values.when_to_send?.id !== WhenToSend.NOW}
            onAfterArchive={onAfterArchive}
            onLoading={onLoading}
            disabled={!reason}
          />
        )}
      </Side.Actions>
    </>
  )
}

export default (props: Props) =>
  // we have to re-mount the form to reset existing data state inside the Form (we call setIsExistingData(true) after submit and we don't need it here)
  props.open ? (
    <SideBar
      variant="wide"
      isOpen
      onClose={props.onClose}
      title={
        props.candidateIds
          ? `Archive ${props.candidateIds.length} ${pluralize(
              'candidate',
              props.candidateIds.length,
            )}`
          : 'Archive candidate'
      }
      subtitle="This will also cancel all the scheduled interviews for the archived opportunity"
    >
      <ArchivingProvider>
        <Form
          api={
            props.candidateIds ? bulkCandidatesEmailsRequests : candidateEmailsRequests
          }
          forceParams={{
            new: 'new',
            candidateId: props.candidate?.id ? String(props.candidate.id) : undefined,
          }}
        >
          <ArchivingCandidateSidebar {...props} />
        </Form>
      </ArchivingProvider>
    </SideBar>
  ) : null
